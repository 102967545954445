module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"家緣數位科技有限公司","short_name":"印刷曙叔","start_url":"/","background_color":"#056353","theme_color":"#056353","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a74156b0ca664b0234e6df4a214ef99f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"874462406461492"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
